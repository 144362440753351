import React from 'react'
import MediaQuery from 'react-responsive'


function Contact() {
  return (
    <div>
        <MediaQuery maxWidth={550}>
            <div className="contacttitle" style={{fontSize:"25px"}}>
                <p>
                    Contact Us <span style={{color : "#9DC947"}}>!</span>
                </p>
            </div>
            <div className="titlefield2">
                    사무실주소
            </div>
            <div className="infofield2">
                    <p>
                        서울시 마포구 매봉산로 18 마포비즈니스센터 603호.<br/>
                        서울시 영등포구 당산로 241 유니언타운 4층.<br/>
                        충남 천안시 서북구 서부역12길 30-14<br/>
                    </p>
            </div>
            <div className="titlefield2">
                    대표 연락처
            </div>
            <div className="infofield2">
                    <p className="namefield" style={{fontSize:"21px"}}>모성환</p>
                    <pre className="customdesign"><span style = {{"color":"#9DC947", fontSize:"15px"}}>Phone</span>        <span style = {{"color":"#3E3E3E", fontSize:"17px"}}>010 - 3599 - 7682</span></pre>
                    <pre className="customdesign"><span style = {{"color":"#9DC947", fontSize:"15px"}}>E-mail</span>       <span style = {{"color":"#3E3E3E", fontSize:"17px"}}>moska@moska-studio.com</span></pre>
            </div>
        </MediaQuery>
        <MediaQuery minWidth={551}>
            <div className="contacttitle">
                <p>
                    Contact Us <span style={{color : "#9DC947"}}>!</span>
                </p>
            </div>
            <div className="wrap">
                <div className="titlefield">
                    사무실주소
                </div>
                <div className="infofield">
                    <p>
                        서울시 마포구 매봉산로 18 마포비즈니스센터 603호.<br/>
                        서울시 영등포구 당산로 241 유니언타운 4층.<br/>
                        충남 천안시 서북구 서부역12길 30-14<br/>
                    </p>
                </div>
            </div>
            <div className="wrap">
                <div className="titlefield">
                    대표 연락처
                </div>
                <div className="infofield">
                    <p className="namefield">모성환</p>
                    <pre className="customdesign"><span style = {{"color":"#9DC947"}}>Phone</span>             <span style = {{"color":"#3E3E3E"}}>010 - 3599 - 7682</span></pre>
                    <pre className="customdesign"><span style = {{"color":"#9DC947"}}>E-mail</span>            <span style = {{"color":"#3E3E3E"}}>moska@moska-studio.com</span></pre>
                </div>
            </div>
        </MediaQuery>
    </div>
  )
}

export default Contact