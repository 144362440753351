import React from 'react'
import {Accordion, Container, Card, Row, Col, Button} from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import MediaQuery from 'react-responsive'


const frontQual = [
    'HTML, CSS 및 JavaScript 생태계에 익숙하신 분',
    'TypeScript, Flow 등 JS 정적 타이핑 툴 경험이 있으신 분',
    'React.js 프로젝트 개발 경험이 있으신 분',
    'Redux 등 상태 관리 패턴 사용 경험이 있으신 분',
    '서버 개발자, 디자인 직군과 원활히 소통하는 능력을 갖추신 분'
]

const backQual = [
    'Relay, GraphQL 사용 경험이 있으신 분',
    'Webpack, Rollup 등 모듈 번들러에 대한 이해가 깊으신 분',
    'Next.js 등 SPA Framework을 이용한 SSR 프로젝트 경험이 있으신 분',
    '모바일 또는 반응형 웹 개발 경험이 있으신 분',
    '오픈소스 Contribution 경험이 있으신 분',
    'UI/UX 개선과 관련된 고민을 좋아하시는 분',
    '새로운 기술에 관심이 많고, 성장을 위해 노력하시는 분'
]
const frontQualList = frontQual.map((qual) => 
    <li className="listItem" style={{fontSize:"16px"}}>{qual}</li>
)
const backQualList = backQual.map((qual) => 
    <li className="listItem" style={{fontSize:"16px"}}>{qual}</li>
)
function CustomToggle({ children, eventKey }) {
  const decoratedOnClick = useAccordionButton(eventKey, () =>
    console.log('totally custom!'),
  );

  return (
    <Button variant = "transparent" className='recbutton' onClick={decoratedOnClick} style={{"float":"right", color:"#1B1F57",fontSize:"22px", fontWeight :"normal"}}>      
        {children}
    </Button>

  );
}
function Recruit() {
  return (
    <div>
        <MediaQuery maxWidth={550}>
            <div className="sample1">
                <img src="/Images/recruit2.png" style={{width:"320px"}}/>
            </div>
            <div className="opening" style={{fontSize:"24px"}}>
                <p>
                    함께 도전하고 함께 성장해나갈<br/>
                    <span style={{color : "#9DC947"}}>모스카 스튜디오</span>의 구성원을 모집합니다 !
                </p>
            </div>
            <div className="acc">
                <Accordion defaultActiveKey="1">
                    <Card style={{border:'none', borderBottom: "1px solid #E1E1E1"}}>
                        <CustomToggle eventKey="0">
                            <Card.Header className='recheader' style={{background: 'none', border:'none', fontSize:"17px", textAlign:"left"}}>
                                Back-End 개발자
                            </Card.Header>
                        </CustomToggle>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <div className='head2'>하는 일</div>
                                <p className='backsub' style={{fontSize:"15px"}}>
                                    모바일 웹뷰 기반의 다양한 신규 사업 개발 및 기존 Product의 UI/UX를 개선해요
                                </p>
                                <div className='head2'>자격요건</div>
                                <ul>
                                    {frontQualList}
                                </ul>
                                <br/><br/>
                                <div className='head2'>우대사항</div>
                                <ul>
                                    {backQualList}
                                </ul>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
                <Accordion defaultActiveKey="1">
                    <Card style={{border:'none', borderBottom: "1px solid #E1E1E1"}}>
                        <CustomToggle eventKey="0">
                            <Card.Header className='recheader' style={{background: 'none', border:'none', fontSize:"17px", textAlign:"left"}}>
                                S/W 개발 총괄
                            </Card.Header>
                        </CustomToggle>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>Nothing here yet!</Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
                <Accordion defaultActiveKey="1">
                    <Card style={{border:'none'}}>
                        <CustomToggle eventKey="0">
                            <Card.Header className='recheader' style={{background: 'none', border:'none', fontSize:"17px", textAlign:"left"}}>
                                콘텐츠 기획 / 서비스 기획   
                            </Card.Header>
                        </CustomToggle>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>Nothing here yet!</Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </div>
        </MediaQuery>
        <MediaQuery minWidth={551}>
        <div className="sample1">
            <img src="/Images/recruit2.png"/>
        </div>
        <div className="opening">
            <p>
                함께 도전하고 함께 성장해나갈<br/>
                <span style={{color : "#9DC947"}}>모스카 스튜디오</span>의 구성원을 모집합니다 !
            </p>
        </div>
        <div className="acc">
            <Accordion defaultActiveKey="1">
                <Card style={{border:'none', borderBottom: "1px solid #E1E1E1"}}>
                    <Card.Header className='recheader' style={{background: 'none', border:'none'}}>
                        Back-End 개발자
                         <CustomToggle eventKey="0">자세히</CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            <div className='head'>하는 일</div>
                            <p className='backsub'>
                                모바일 웹뷰 기반의 다양한 신규 사업 개발 및 기존 Product의 UI/UX를 개선해요
                            </p>
                            <div className='head'>자격요건</div>
                            <ul>
                                {frontQualList}
                            </ul>
                            <br/><br/>
                            <div className='head'>우대사항</div>
                            <ul>
                                {backQualList}
                            </ul>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
            <Accordion defaultActiveKey="1">
                <Card style={{border:'none', borderBottom: "1px solid #E1E1E1"}}>
                    <Card.Header className='recheader' style={{background: 'none', border:'none'}}>
                        Front-End 개발자
                         <CustomToggle eventKey="0">자세히</CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>Nothing here yet!</Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
            
            <Accordion defaultActiveKey="1">
                <Card style={{border:'none'}}>
                    <Card.Header className='recheader' style={{background: 'none'}}>
                        광고 세일즈 담당자
                         <CustomToggle eventKey="0">자세히</CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>Nothing here yet!</Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </div>
        </MediaQuery>
    </div>
  )
}

export default Recruit