import React from 'react'
import { Button} from 'reactstrap'

function Footer() {
  return (
        <div className="footer">
            <p className="footerintro">
                서울시 마포구 매봉산로 18 마포비즈니스센터 603호.<br/>
                서울시 영등포구 당산로 241 유니언타운 4층.<br/>
                충남 천안시 서북구 서부역12길 30-14
            </p>
            <p className="footerclose">
                모스카 스튜디오 Moska Studio Co. , Ltd.
            </p>
            <div className="github">
                <Button outline className="btnn" href="https://github.com/moskalabs"><img src = "/Images/githubButton.png"/></Button>
            </div>
        </div>
  )
}

export default Footer