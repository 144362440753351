import Header from './Components/Header'
import CompanyIntro from './Components/CompanyIntro';
import Footer from './Components/Footer';
import Recruit from './Components/Recruit';
import Contact from './Components/Contact'
import Team from './Components/Team'
import ScrollToTop from './Components/ScrollToTop';
import {
  Routes,
  Route,
  useLocation
} from "react-router-dom";
import React, { useState, useEffect } from 'react';
function App() {
    const [selected, setSelected] = useState(window.location.href.split("/").slice(-1)[0])
    const location = useLocation() 
    useEffect(() => {
      setSelected(location.pathname.split("/").slice(-1)[0]);
  }, [location]);

  return (
    <div>
      <Header selected = {selected}/>
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<CompanyIntro selected ={selected}/>}/>
          <Route path="/team" element={<Team/>}/>
          <Route path="/recruitment" element={<Recruit/>}/>
          <Route path="/contact" element={<Contact/>}/>
        </Routes>
      </ScrollToTop>
      <Footer />
    </div>
  );
}

export default App;
