import React from 'react'
import {Row, Col} from 'reactstrap'
import MediaQuery from 'react-responsive'
import './style.css'


function workingPrinciples(number, title, text) {
    return(
            <Col xs = '6'>
                <p className="culturenumber">{number}</p>
                <p className="culturetitle">{title}</p>
                <p className="culturetext" style={{width:"100%"}}>
                    {text[0]}
                    <br/><br/>
                    {text[1]}
                </p>         
            </Col>
    )
}
function workingPrinciples2(number, title, text) {
    return(
            <Col xs ='12'>
                <p className="culturenumber">{number}</p>
                <p className="culturetitle" style={{fontSize:"24px"}}>{title}</p>
                <p className="culturetext" style={{fontSize:"17px", width:"100%"}}>
                    {text[0]}
                    <br/><br/>
                    {text[1]}
                </p> 
                
            </Col>
    )
}
function makeIllustrations2(picture,contents, reverse) {
        return (
        <>
        <p className="culturenumber" style={{"font-size":"40px", textAlign:"center", fontSize:"25px"}}>{contents[0]}</p>
        <Col xs="12"  style={{"text-align":"center", "margin-bottom":"0px"}}><img src = {"/Images/" + picture + ".png"} style={{"width":"234px"}}/></Col>
        <Col xs="12">
            <div className="illustrations2">
                <p className='illtitle' style={{fontSize:"25px"}}>{contents[1]}</p>
                <p className='illtext2'>
                    {contents[2][0]}<br/>
                    {contents[2][1]}<br/>
                    {contents[2][2]}
                </p>
                
            </div>
        </Col>
        </>
        )
}
function makeIllustrations(picture,contents, reverse) {
    if (reverse) {
        return (
        <>
        <Col xs="6"  style={{"text-align":"center", "margin-bottom":"100px"}}><img src = {"/Images/" + picture + ".png"} style={{"width":"352px"}}/></Col>
        <Col xs="6">
            <div className="illustrations">
                <p className="culturenumber" style={{"font-size":"40px"}}>{contents[0]}</p>
                <p className='illtitle'>{contents[1]}</p>
                <p className='illtext' style={{width:"100%"}}>{contents[2][0]}</p>
                <p className='illtext' style={{width:"100%"}}>{contents[2][1]}</p>
                <p className='illtext' style={{width:"100%"}}>{contents[2][2]}</p>
            </div>
        </Col>
        </>
        )
    }
    return (
        <>
        <Col xs="6">
            <div className="illustrations">
                <p className="culturenumber" style={{"font-size":"40px"}}>{contents[0]}</p>
                <p className='illtitle'>{contents[1]}</p>
                <p className='illtext' style={{width:"100%"}}>{contents[2][0]}</p>
                <p className='illtext' style={{width:"100%"}}>{contents[2][1]}</p>
                <p className='illtext' style={{width:"100%"}}>{contents[2][2]}</p>
            </div>
        </Col>
        <Col xs="6"  style={{"text-align":"center", "margin-bottom":"100px"}}><img src = {"/Images/" + picture + ".png"}/></Col>
        </>
    )
}

function Team() {
  return (
    <div>
        <MediaQuery maxWidth={550}>
            <div className="opening" style={{"fontSize":"24px"}}>
                <p>
                    우리는 <span style={{color : "#9DC947"}}>서로</span>를 통해  <span style={{color : "#9DC947"}}>배웁니다.</span>
                </p>
            </div>
            <div className="teamphoto">
                <Row>
                    <Col xs = "12"><img src="/Images/team1.png" style={{"borderRadius": "13px", "width":"340px", "marginBottom":"20px"}}/> </Col>
                    <Col xs = "12"><img src="/Images/team2.png" style={{"borderRadius": "13px", "width":"340px"}}/> </Col>
                </Row>
            </div>
            <div className="teamculture" style={{"fontSize":"17px", width:"80%", marginLeft:"10%", marginBottom:"50px"}}>
                <p>
                    ‘연결’의 가치를 발견하고, 콘텐츠를 통해 
                    소비자 개개인의 삶에 긍정적인 영향을 드리고 
                    싶습니다.
                </p>
            </div>
            <div className="opening" style={{fontSize:"24px"}}>
                <p>
                    우리가 일하는 방식이에요
                </p>
            </div>
            <div className="cultureprinciples">
                <Row>
                    {workingPrinciples2('01','더 배우고 싶어요.', ['관련 최신 기술, 새로운 방식의 접근법등, 더 나은 방식을 추구해요', '인프런, 패스트 캠퍼스, 관련 도서 구입지원을 회사 차원에서 하고 있어요.'])}
                    {workingPrinciples2('02','자유롭게 소통해요.', ['직급에 상관없이, 자신의 주장과 방식을합리적으로 이야기하려 해요.', '자유로운 소통을 통해더 나은 결정을 할 수 있다고 믿어요.'])}
                    {workingPrinciples2('03','함께 이루고 싶어요.', ['개개인은 모두 가지고 있는 생각과 능력이 달라요.다양한 생각과 역할이 모여서 더 나은 결과물을 만들어 낼 수 있다고 생각해요.혼자가 아닌 함께 일하고 싶어요.'])}
                    {workingPrinciples2('04','정보를 공유해요.', ['직급과 역할에 따라, 특정 정보를 독점하지 않으려 해요.누구나 정보에 접근할 수 있고, Asana 와 네이버 웍스, Slack, 을 활용해투명한 정보와 함께 일할 수 있어요.'])}
                    {workingPrinciples2('05','즐겁게 일하고 싶어요.', ['재밌는 서비스와, 매력적인 사람들과 함께 일해요.심 식사 뒤로는 같이 한강 산책을 하기도 해요.즐겁게 만들어야 즐거운 서비스가 만들어진다고 믿어요'])}
                    {workingPrinciples2('06','책임지고, 자유롭고 싶어요.', ['출근 시간은 8시~12시 , 퇴근 시간은 17시~21시 로자유롭게 출퇴근하고 자신의 업무 결과에 책임을 져요.더 자유롭게 일하고, 더 책임있는 결과물을 내려고 해요.'])}
                    {workingPrinciples2('07','사용자에 관심이 많아요.', ['사용자가 겪는 불편함이 무엇인지에 관심이 많아요.사용자가 왜 우리 서비스를 이용하는지, 어디에서 불편함을 느끼는지 어떤 부분이 바뀌었으면 좋겠다고 생각하는지에 관심이 많아요.사용자를 우선으로 생각해야 더 나은 서비스를 만들 수 있다고 믿어요'])}
                </Row>
            </div>
            <div className="opening" style={{fontSize:"24px"}}>
                <p>
                    리비도 팀이<br/> 
                    서비스에 몰입할 수 있도록<br/>
                    지원하고 있어요.
                </p>
            </div>
            <div className="cultureprinciples">
                <Row>
                    {makeIllustrations2("ill1", ['01', '유연한 출퇴근', ['8 - 12시 출근','17 - 21시 퇴근','']], true)}
                    {makeIllustrations2("ill2", ['02', '몰입하기 위한 환경', ['맥북 지급, 듀얼 모니터 지급','태블릿 등 각종 기자재 지급','간식, 커피 지원']], false)}
                    {makeIllustrations2("ill3", ['03', '커리어 성장', ['인프런 강의 지원','패스트 캠퍼스 강의 지원','도서 구입 지원']], true)}
                    {makeIllustrations2("ill4", ['04', '즐거운 환경', ['점심 식사 후, 한강 산책','다 같이 영화 감상', '고궁 투어 등 소풍']], false)}
                </Row>
            </div>
        </MediaQuery>
        <MediaQuery minWidth={551}>
            <div className="opening">
                <p>
                    우리는 <span style={{color : "#9DC947"}}>서로</span>를 통해  <span style={{color : "#9DC947"}}>배웁니다.</span>
                </p>
            </div>
            <div className="teamphoto">
                <Row>
                    <Col xs = "6"><img src="/Images/team1.png" style={{"borderRadius": "13px", width:"100%", height:"100%"}}/> </Col>
                    <Col xs = "6"><img src="/Images/team2.png" style={{"borderRadius": "13px", width:"100%", height:"100%"}}/> </Col>
                </Row>
            </div>
            <div className="teamculture">
                <p>
                    ‘연결’의 가치를 발견하고, 콘텐츠를 통해<br/>
                    소비자 개개인의 삶에 긍정적인 영향을 드리고 싶습니다.<br/>
                    <br/>
                    소비자가 무엇을 ‘연결' 하고 싶어하는지<br/>
                    끊임 없이 찾아보고, 검증해나가는 것을 즐기고 있습니다.<br/>
                    <br/>
                    우리는 소비자와, 동료와, 서비스를 통해 성장하고 있습니다.
                </p>
            </div>
            <div className="opening">
                <p>
                    우리가 일하는 방식이에요
                </p>
            </div>
            <div className="cultureprinciples">
                <Row>
                    {workingPrinciples('01','더 배우고 싶어요.', ['관련 최신 기술, 새로운 방식의 접근법등, 더 나은 방식을 추구해요', '인프런, 패스트 캠퍼스, 관련 도서 구입지원을 회사 차원에서 하고 있어요.'])}
                    {workingPrinciples('02','자유롭게 소통해요.', ['직급에 상관없이, 자신의 주장과 방식을합리적으로 이야기하려 해요.', '자유로운 소통을 통해더 나은 결정을 할 수 있다고 믿어요.'])}
                    {workingPrinciples('03','함께 이루고 싶어요.', ['개개인은 모두 가지고 있는 생각과 능력이 달라요.다양한 생각과 역할이 모여서 더 나은 결과물을 만들어 낼 수 있다고 생각해요.혼자가 아닌 함께 일하고 싶어요.'])}
                    {workingPrinciples('04','정보를 공유해요.', ['직급과 역할에 따라, 특정 정보를 독점하지 않으려 해요.누구나 정보에 접근할 수 있고, Asana 와 네이버 웍스, Slack, 을 활용해투명한 정보와 함께 일할 수 있어요.'])}
                    {workingPrinciples('05','즐겁게 일하고 싶어요.', ['재밌는 서비스와, 매력적인 사람들과 함께 일해요.심 식사 뒤로는 같이 한강 산책을 하기도 해요.즐겁게 만들어야 즐거운 서비스가 만들어진다고 믿어요'])}
                    {workingPrinciples('06','책임지고, 자유롭고 싶어요.', ['출근 시간은 8시~12시 , 퇴근 시간은 17시~21시 로자유롭게 출퇴근하고 자신의 업무 결과에 책임을 져요.더 자유롭게 일하고, 더 책임있는 결과물을 내려고 해요.'])}
                    {workingPrinciples('07','사용자에 관심이 많아요.', ['사용자가 겪는 불편함이 무엇인지에 관심이 많아요.사용자가 왜 우리 서비스를 이용하는지, 어디에서 불편함을 느끼는지 어떤 부분이 바뀌었으면 좋겠다고 생각하는지에 관심이 많아요.사용자를 우선으로 생각해야 더 나은 서비스를 만들 수 있다고 믿어요'])}
                </Row>
            </div>
            <div className="opening">
                <p>
                    리비도 팀이 서비스에 몰입할 수 있도록<br/>
                    지원하고 있어요.
                </p>
            </div>
            <div className="cultureprinciples">
                <Row>
                    {makeIllustrations("ill1", ['01', '유연한 출퇴근', ['8 - 12시 출근','17 - 21시 퇴근','']], true)}
                    {makeIllustrations("ill2", ['02', '몰입하기 위한 환경', ['맥북 지급, 듀얼 모니터 지급','태블릿 등 각종 기자재 지급','간식, 커피 지원']], false)}
                    {makeIllustrations("ill3", ['03', '커리어 성장', ['인프런 강의 지원','패스트 캠퍼스 강의 지원','도서 구입 지원']], true)}
                    {makeIllustrations("ill4", ['04', '즐거운 환경', ['점심 식사 후, 한강 산책','다 같이 영화 감상, 고궁 투어 등 소풍','']], false)}
                </Row>
            </div>
        </MediaQuery>
    </div>
  )
}

export default Team