import 'bootstrap/dist/css/bootstrap.css';
import './style.css'
import {Navbar, NavbarBrand, Collapse, NavItem, Nav, NavbarToggler} from 'reactstrap'
import React, { useState } from 'react';
import {NavLink as Linkk} from 'react-router-dom'
function Header(props) {
    const [isOpen, setIsOpen] = useState(false)
    function toggle() {
        setIsOpen(!isOpen)
    }
    return (
        <div>
            <Navbar color="white" light expand="sm" className='py-4' style={{'paddingLeft':'10%', 'paddingRight':"10%"}}>
                <NavbarBrand href="/"> 
                    <img src = '/Images/libido.png'/>
                </NavbarBrand>
                <NavbarToggler onClick={toggle} color='dark'/>
                <Collapse  isOpen={isOpen} navbar>
                    <Nav className="ms-auto" navbar style={{textAlign:"right"}}>
                        <NavItem>
                            <Linkk to = "/" className= {props.selected == "" ? 'CustomNavItem3' : 'CustomNavItem2'}>회사 소개</Linkk>
                        </NavItem>
                        <NavItem >
                            <Linkk to = "/team"  className= {props.selected == "team" ? 'CustomNavItem3' : 'CustomNavItem2'}>모스카 팀</Linkk>
                        </NavItem>
                        <NavItem>
                            <Linkk to = "/recruitment" className= {props.selected == "recruitment" ? 'CustomNavItem3' : 'CustomNavItem2'}>채용</Linkk>
                        </NavItem>
                        <NavItem>
                            <Linkk to = "/contact" className= {props.selected == "contact" ? 'CustomNavItem3' : 'CustomNavItem2'}>CONTACT</Linkk>
                        </NavItem>
                    </Nav>
                </Collapse>
            </Navbar>
    </div>
    );
    }

export default Header;
