import './style.css'
import {Row, Col, Button} from 'reactstrap'
import MediaQuery from 'react-responsive'
import {NavLink as Linkk} from 'react-router-dom'

function CompanyIntro(props) {

function constructCard(name, role, description, photo) {
    return (
        <div className='item'>
                <div className="picture"><img src = {"/Images/" + photo + ".png"} className='teamimg'/></div>
                <div className="name">
                    <p>{name}</p>
                </div>
                <div className="role">
                    <p>{role}</p>
                </div>
                <div className="description">
                    <p>{description}</p>
                </div>
        </div>
    )
}
function leftScroll() {
    document.getElementsByClassName('container')[0].scrollLeft -= 250;
}
function rightScroll() {
    document.getElementsByClassName('container')[0].scrollLeft += 250;
}
function sectionIntro(title, subtitle) {
    return (
        <div className="servicetitle">
        <p className="minititle">
            {title}
        </p>
        <p className="minisubtitle">
            {subtitle}
        </p>
    </div>
    )
  }
function subsection(picture, text, className, page) {
    return (
    <div className="culture">
        <Row>
            <Col xs="6"><img src = {"/Images/" + picture + ".png"} style={{width:"100%"}}/></Col>
            <Col xs="6">
                    <p className={className} style={{width:"100%"}}>
                        {text}
                    </p>
                    <Linkk to = {"/"+page}>
                        <Button outline color = "success" className="custombtn">
                            see more
                        </Button>
                    </Linkk>
            </Col>
        </Row>
    </div>
    )
}
function subsection2(picture, text, className, page) {
    return (
    <div className="culture">
        <Row>
            <Col xs="12"><img src = {"/Images/" + picture + ".png"} style={{width:"333px"}}/></Col>
            <Col xs="12">
                <div style={{textAlign:"center"}}>
                    <p className={className}>
                        {text}
                    </p>
                    <Linkk to = {"/"+page}>
                        <Button outline color = "success" className="custombtn2">
                            see more
                        </Button>
                    </Linkk>
                </div>
            </Col>
        </Row>
    </div>
    )
}
return (
<div>
    <MediaQuery maxWidth={550}>
        <div className="uppertitle" style={{fontSize:"24px"}}>
            <p>
                <span style={{color : "#9DC947"}}>마음</span>과 <span style={{color : "#9DC947"}}>마음</span>을 연결합니다.
            </p>
        </div>
        <div className="sample1"><img src = "/Images/sample1.png" style={{width: "60%"}}/></div>
        <div className="openingtitle2">
            <p>
                혼자가 아닌 함께
            </p>
        </div>
        <div className="openingtext2">
            <p>
                모스카 스튜디오는 함께여서<br/> 
                더욱 즐거운 세상을 꿈꿉니다.<br/>
                당신의 세상과 연결하고 싶습니다.
            </p>
        </div>
        <div className="borderr"></div>
        {sectionIntro("서비스 소개", "Our Service")}
        <div className="outer">
            <div className="libidotitle2">
                <p>
                    리비도
                </p>
            </div>
            <div className="libidosubtitle2">
                <p>
                    ONTACT 방식의<br/>
                    컨텐츠 실시간 소통 서비스
                </p>
            </div>
        </div>
        <div className="sample1"><img src = "/Images/homeBackground.png" style={{width:"80%", marginLeft:"4%"}}/></div>
        <div className="libidointro" style={{fontSize: "17px"}}>
            <p>
                오늘 뭐 보지?<br/>
                유튜브? 넷플릭스? 왓챠?<br/>
                <br/>
                OTT서비스를 눌렀지만 막상 뭘 봐야 할지 항상 고민이에요 :)<br/>
                ‘리비도' 앱을 누르면 유튜브, 넷플릭스 심지어 웹툰까지 한번에 볼 수 있다구욧!!<br/>
                <br/>
                더욱 놀라운 사실은 친구를 초대해서 같은 영상을 같이 볼 수 있다는 거예요!!🏻‍♀️ <br/>
                <br/>
                내가 좋아하는 ‘친구', 관심사가 비슷한 ‘친구'의 <br/>
                마음과 마음을 연결하는 소통 서비스. ‘리비도' 입니다.😁
            </p>
        </div>
        {sectionIntro("팀 소개", "Our Team")}
        <div className="libidointro" style={{fontSize: "17px"}}>
            <p>
                관심사, 취향이 비슷한 사람들을 콘텐츠로 연결하고,<br/>
                이를 통해 콘텐츠를 소비하는 시간을 더욱 의미있게 만들기 위해 모였어요.<br/>
                <br/>
                만드는 사람이 한 번 더 신경쓸수록, 사용하는 사람이 더욱 재미있게 몰입할 수 있다<br/>
                는 생각으로, 서비스를 조금이라도 개선하려 애쓰고 있습니다.
            </p>
        </div>
        <div className='team2'>
            <div className="container" style={{overflow:"scroll"}}>
                {constructCard("모성환", "CEO", "모스카 스튜디오는 언제나 도전하고 있습니다. 새로운 도전을 끊임없이 시도합니다.", "ceo")}
                {constructCard("이정빈", "App Front-End Engineer", "모스카 스튜디오는 언제나 도전하고 있습니다. 새로운 도전을 끊임없이 시도합니다.", "appfront")}
                {constructCard("Alisher Sembekov", "Front-End Engineer", "모스카 스튜디오는 언제나 도전하고 있습니다. 새로운 도전을 끊임없이 시도합니다.", "front")}
                {constructCard("김성은", "UI&UX Designer", "모스카 스튜디오는 언제나 도전하고 있습니다. 새로운 도전을 끊임없이 시도합니다.", "uiux")}
            </div>
        </div>
        {sectionIntro("팀 문화", "Team Culture")}
        {subsection2("culture", `우리는 ‘연결'을 통해 개개인의 삶에 긍정적인 영향을 드리고 싶습니다.`, "cultureintro2", "team")}
        {sectionIntro("채용", "Recruit")}
        {subsection2("recruit", `모스카 스튜디오와 함께할 사람들을 모집합니다.`, "recruitintro2", "recruitment")}
    </MediaQuery>
    <MediaQuery minWidth={551}>
        <div className="uppertitle">
            <p>
                <span style={{color : "#9DC947"}}>마음</span>과 <span style={{color : "#9DC947"}}>마음</span>을 연결합니다.
            </p>
        </div>
        <div className="sample1"><img src = "/Images/sample1.png" style={{width: "60%"}}/></div>
        <div className="openingtitle">
            <p>
                혼자가 아닌 함께
            </p>
        </div>
        <div className="openingtext">
            <p>
                모스카 스튜디오는 함께여서 더욱 즐거운 세상을 꿈꿉니다.<br/>
                당신의 세상과 연결하고 싶습니다.
            </p>
        </div>
        <div className="borderr"></div>
        {sectionIntro("서비스 소개", "Our Service")}
        <div className="outer">
            <div className="libidotitle">
                <p>
                    리비도
                </p>
            </div>
            <div className="libidosubtitle">
                <p>
                    ONTACT 방식의<br/>
                    컨텐츠 실시간 소통 서비스
                </p>
            </div>
        </div>
        <div className="sample1"><img src = "/Images/homeBackground.png" style={{width:"80%"}}/></div>
        <div className="libidointro">
            <p>
                오늘 뭐 보지?<br/>
                유튜브? 넷플릭스? 왓챠?<br/>
                <br/>
                OTT서비스를 눌렀지만 막상 뭘 봐야 할지 항상 고민이에요 :)<br/>
                ‘리비도' 앱을 누르면 유튜브, 넷플릭스 심지어 웹툰까지 한번에 볼 수 있다구욧!!<br/>
                <br/>
                더욱 놀라운 사실은 친구를 초대해서 같은 영상을 같이 볼 수 있다는 거예요!!🏻‍♀️ <br/>
                <br/>
                내가 좋아하는 ‘친구', 관심사가 비슷한 ‘친구'의 <br/>
                마음과 마음을 연결하는 소통 서비스. ‘리비도' 입니다.😁
            </p>
        </div>
        {sectionIntro("팀 소개", "Our Team")}
        <div className="libidointro">
            <p>
                관심사, 취향이 비슷한 사람들을 콘텐츠로 연결하고,<br/>
                이를 통해 콘텐츠를 소비하는 시간을 더욱 의미있게 만들기 위해 모였어요.<br/>
                <br/>
                만드는 사람이 한 번 더 신경쓸수록, 사용하는 사람이 더욱 재미있게 몰입할 수 있다<br/>
                는 생각으로, 서비스를 조금이라도 개선하려 애쓰고 있습니다.
            </p>
        </div>
        <div className='team'>
            <Button  outline id="scrolll" className="leftbtn" onClick={leftScroll}><img src = "/Images/left.png" style={{width:"64px"}}/></Button>
            <div className="container">
                {constructCard("모성환", "CEO", "모스카 스튜디오는 언제나 도전하고 있습니다. 새로운 도전을 끊임없이 시도합니다.", "ceo")}
                {constructCard("이정빈", "App Front-End Engineer", "모스카 스튜디오는 언제나 도전하고 있습니다. 새로운 도전을 끊임없이 시도합니다.", "appfront")}
                {constructCard("Alisher Sembekov", "Front-End Engineer", "모스카 스튜디오는 언제나 도전하고 있습니다. 새로운 도전을 끊임없이 시도합니다.", "front")}
                {constructCard("김성은", "UI&UX Designer", "모스카 스튜디오는 언제나 도전하고 있습니다. 새로운 도전을 끊임없이 시도합니다.", "uiux")}
            </div>
            <Button  outline id="scrollr" className="rightbtn" onClick={rightScroll}><img src = "/Images/right.png"/></Button>
        </div>
        {sectionIntro("팀 문화", "Team Culture")}
        {subsection("culture", `우리는 ‘연결'을 통해 개개인의 삶에 긍정적인 영향을 드리고 싶습니다.`, "cultureintro", "team")}
        {sectionIntro("채용", "Recruit")}
        {subsection("recruit", `모스카 스튜디오와 함께할 사람들을 모집합니다.`, "recruitintro", "recruitment")}
    </MediaQuery>
</div>
);
}

export default CompanyIntro;
